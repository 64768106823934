<template>
  <v-container>
    <Logo class="logo" />
    <v-form
     @submit.prevent="submit"
      ref="form"
      lazy-validation
      v-if="true"
    >
      <v-row justify="center" v-if="!this.message">
        <v-col class="col-form pb-12 pt-12" md="6" cols="10">
          <v-row justify="center">
            <v-col md="6" cols="10" class="title-text">Digite a nova senha da sua conta Finago:</v-col>
            <v-col md="8" cols="10" class="pb-0">
              <v-text-field
                class="password"
                :append-icon="showNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :type="showNewPassword ? 'text' : 'password'"
                @click:append="showNewPassword = !showNewPassword"
                v-model="password"
                :rules="passwordRules"
                label="Nova senha"
              ></v-text-field>
            </v-col>
            <v-col md="8" cols="10" class="pt-0">
              <v-text-field
                class="confirmPassword"
                :append-icon="showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                v-model="confirmPassword"
                :rules="passwordConfirmationRules"
                label="Confirmar nova senha"
              ></v-text-field>
            </v-col>
            <v-col md="8" cols="10" class="button pt-12 pt-md-6">
              <v-btn class="btn-reset"
                rounded large block outlined color="#D70472" @click="validate">Alterar senha</v-btn>
            </v-col>
            <v-col md="8" cols="12" class="col-cancel pt-0">
              <a @click="back" class="cancel">Cancelar alteração</a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="this.message">
        <v-col class="col-form pb-12 pt-12" md="6" cols="10">
          <v-row justify="center">
            <v-col cols="12">
              <v-row justify="center">
                <img class="imgSuccess mt-md-1 mt-4" src="@/assets/successPink.png" />
              </v-row>
            </v-col>
            <v-col md="8" cols="12" class="title-text pb-8">Senha alterada com sucesso!</v-col>
            <v-col md="8" cols="12" class="button">
              <v-btn class="btn-reset"
                rounded large block outlined color="#D70472" @click="back">Acessar Portal Finago</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <div class="text" v-else>
        <p>O seu link de recuperação de senha já expirou.
          Para solicitar um novo clique <a href="#" @click="redirect">aqui</a>.
        </p>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Logo from '../../../components/Logo.vue';

export default {
  components: {
    Logo,
  },
  data() {
    return {
      showConfirmPassword: false,
      showNewPassword: false,
      password: '',
      confirmPassword: '',
      passwordConfirmationRules: [
        valueInput => !!valueInput || 'Senha de confirmação obrigatória',
        () => this.password === this.confirmPassword
          || 'Senhas devem ser iguais',
      ],
      passwordRules: [
        valueInput => !!valueInput || 'Senha obrigatória',
        valueInput => (valueInput && valueInput.length >= 8)
          || 'Senha deve ter até 8 caracteres ou mais',
        valueInput => (valueInput && valueInput.match(/\W/))
          || 'Senha deve ter ao menos um caracter especial',
        valueInput => (valueInput && valueInput.match(/[A-Z]/))
          || 'Senha deve ter ao menos um caracter maiúsulo',
        valueInput => (valueInput && valueInput.match(/[0-9]/))
          || 'Senha deve ter ao menos um número',
      ],
    };
  },
  methods: {
    ...mapActions(
      {
        validateTokenPasswordRecover: 'Auth/validateTokenPasswordRecover',
        resetPassword: 'Auth/resetPassword',
      },
    ),
    validate() {
      if (this.$refs.form.validate()) {
        this.resetPassword({
          password: this.password,
          token: this.queryToken,
        });
      }
    },
    redirect() {
      this.$router.push({ name: 'forgotPassword' });
    },
    back() {
      this.$router.push({ path: 'login' });
    },
  },
  computed: {
    ...mapGetters(
      {
        validToken: 'Auth/getValidToken',
        message: 'Auth/getMessagePassword',
      },
    ),
    queryToken() {
      return this.$route.query.token;
    },
  },
  async created() {
    await this.validateTokenPasswordRecover(this.queryToken);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/commons/resetPassword.scss';
</style>
