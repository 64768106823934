<template>
    <div class="container">
        <img src="../assets/logo.png" class="logo" alt="Finago">
    </div>
</template>

<script>

export default {
  name: 'Logo',
};
</script>

<style lang="scss" scoped>
    .container {
        display: flex;
        justify-content: space-evenly;
    }

    @media only screen and (max-device-width: 700px)  {
    /* For mobile phones: */
    .container {
    width: 40%;
    padding-bottom: 10%;
    }
  }
</style>
